import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert } from "react-bootstrap";
import WorkoutProgramDayForm from "./WorkoutProgramDayForm";
import ProgramDayHelper from "../../../helpers/programs/ProgramDayHelper";
import ContentHeader from "../../ContentHeader";

function EditProgramDay() {
    const { dayId, programId } = useParams();
    const navigate = useNavigate();

    const [error, setError] = useState('');

    const saveDay = (name) => {
        ProgramDayHelper.updateDay(programId, dayId, name)
            .then(() => {
                // Redirect to programs
                navigate('/programs');
            })
            .catch(() => {
                setError('Päivitys epäonnistui.');
            });
    };

    const showError = (text) => {
        setError(text);
    };

    return (
        <Row className="mb-5">
            <ContentHeader title="Muokkaa harjoituspäivää" />

            {error && (
                <Col xs={12}>
                    <Alert variant="danger">{error}</Alert>
                </Col>
            )}

            <Col xs={12}>
                <WorkoutProgramDayForm
                    saveBtnText="Tallenna"
                    showError={showError}
                    dayId={dayId}
                    programId={programId}
                    loadData={true}
                    saveDay={saveDay}
                    historyBack={() => navigate(-1)}
                />
            </Col>
        </Row>
    );
}

export default EditProgramDay;
