import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert } from "react-bootstrap";
import ProgramHelper from "../../helpers/programs/ProgramHelper";
import WorkoutProgramForm from "./WorkoutProgramForm";
import ContentHeader from "../ContentHeader";

function NewProgram() {
    const navigate = useNavigate();

    const [error, setError] = useState('');

    const createProgram = (name, description) => {
        ProgramHelper.createProgram(name, description)
            .then(() => {
                // Redirect to programs
                navigate('/programs');
            })
            .catch(() => {
                setError('Ohjelman luonti epäonnistui.');
            });
    };

    const showError = (text) => {
        setError(text);
    };

    return (
        <Row className="mb-5">
            <ContentHeader title="Uusi ohjelma" />

            {error && (
                <Col xs={12} className="mb-3">
                    <Alert variant="danger">{error}</Alert>
                </Col>
            )}

            <Col xs={12}>
                <WorkoutProgramForm
                    saveBtnText="Lisää"
                    showError={showError}
                    id=""
                    loadData={false}
                    saveProgram={createProgram}
                    historyBack={() => navigate(-1)}
                />
            </Col>
        </Row>
    );
}

export default NewProgram;
