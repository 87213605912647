import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { isUserLogged } from '../redux/reducers/user';

function PrivateRoute({ element, logged }) {
    return logged ? element : <Navigate to="/" />;
}

function mapStateToProps(state) {
    return {
        logged: isUserLogged(state),
    };
}

export default connect(mapStateToProps)(PrivateRoute);