import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Alert, Button} from "react-bootstrap";
import WorkoutHelper from "../../helpers/workoutHelper";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import WorkoutMove from "./WorkoutMove";
import SetForm from "./SetForm";
import _ from "lodash";
import WorkoutSetHelper from "../../helpers/workouts/WorkoutSetHelper";
import ContentHeader from "../ContentHeader";
import WorkoutMoveForm from "./WorkoutMoveForm";
import WorkoutMoveHelper from "../../helpers/workouts/WorkoutMoveHelper";
import {DndContext} from "@dnd-kit/core";
import {SortableContext} from '@dnd-kit/sortable';
import RPESelector from "./rpe/RPESelector";
import {Link, useParams} from "react-router-dom";
import RouteHelper from "../../helpers/RouteHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faPlus} from "@fortawesome/free-solid-svg-icons";

const GymWorkout = () => {
    const { id } = useParams();
    const [collapsedMoves, setCollapsedMoves] = useState([]);
    const [error, setError] = useState('');
    const [moveForm, setMoveForm] = useState({
        show: false,
        isNewMove: true,
        workoutMoveId: 0,
        moveId: 0,
        comments: '',
    });
    const [scrollPosY, setScrollPosY] = useState(0);
    const [setForm, setSetForm] = useState({
        show: false,
        moveId: 0,
        setGroup: 0,
        setData: [],
    });
    const [workout, setWorkout] = useState([]);

    useEffect(() => {
        if (id) {
            WorkoutHelper.getWorkout(id)
                .then((data) => setWorkout(data))
                .catch(() => setError('Treenin haku epäonnistui.'));
        } else {
            setError('Treenin haku epäonnistui.');
        }
    }, [id]);

    const setScrollPosition = () => {
        setScrollPosY(window.pageYOffset);
    };

    const restoreScrollPosition = () => {
        window.scrollTo(0, scrollPosY);
    };

    const toggleLinkWithNextMove = (moveId) => {
        const allMoves = workout.moves;
        const moveIndex1 = _.findIndex(allMoves, { id: moveId });
        let linkGroups = true;

        if (moveIndex1 >= 0) {
            const move1 = allMoves[moveIndex1];
            const move2 = allMoves[moveIndex1 + 1];

            if (
                move2 &&
                move1.set_group > 0 &&
                move2.set_group > 0 &&
                move2.set_group === move1.set_group
            ) {
                linkGroups = false;
            }
        }

        const updateFn = linkGroups
            ? WorkoutMoveHelper.linkMove
            : WorkoutMoveHelper.unlinkMove;

        updateFn(workout.id, moveId)
            .then((moves) => showLinkUpdates(moves))
            .catch(() => {});
    };

    /**
     * Show / hide move information
     * @param workoutMoveId
     * @param open
     */
    const toggleMove = (workoutMoveId, open) => {
        setCollapsedMoves((prev) =>
            open ? prev.filter((id) => id !== workoutMoveId) : [...prev, workoutMoveId]
        );
    };

    const showLinkUpdates = (moves) => {
        const updatedWorkout = { ...workout };

        moves.forEach((m) => {
            const moveIndex = _.findIndex(updatedWorkout.moves, { id: m.id });
            if (moveIndex >= 0) {
                updatedWorkout.moves[moveIndex].set_group = m.group;
            }
        });

        setWorkout(updatedWorkout);
    };

    const showSetForm = (moveId, setGroup) => {
        setScrollPosition();
        const move = _.find(workout.moves, { id: moveId });
        const setGroups = move?.set_groups?.find((group) => group.group === setGroup);

        setSetForm({
            show: true,
            moveId,
            setGroup,
            setData: setGroups?.sets || [],
        });
    };

    const closeSetForm = () => {
        setSetForm({
            show: false,
            moveId: 0,
            setGroup: 0,
            setData: [],
        });
        restoreScrollPosition();
    };

    const showEditedSet = (moveId, newSets, newRefValue) => {
        setWorkout((prevWorkout) => {
            const updatedWorkout = { ...prevWorkout }; // Create a copy of the current workout state
            const moveIndex = _.findIndex(updatedWorkout.moves, (move) => move.id === moveId);

            if (moveIndex >= 0) {
                // Update the set_groups and ref_value for the specified move
                updatedWorkout.moves[moveIndex] = {
                    ...updatedWorkout.moves[moveIndex],
                    set_groups: newSets,
                    ref_value: newRefValue,
                };
            }

            return updatedWorkout;
        });

        closeSetForm();
    };

    const deleteSet = (moveId, setGroup) => {
        WorkoutSetHelper.deleteSet(
            workout.id, moveId, setGroup
        ).then(function (newSets) {
            showEditedSet(moveId, newSets.sets, newSets.ref_value);

        }.bind(this))
            .catch(error => {
                setError('Poisto epäonnistui.');
            })
    };

    /* Moves */

    const showMoveForm = (isNewMove, workoutMoveId) => {
        setScrollPosition();
        let comments = "";
        let moveId = 0;

        if (workout) {
            const moves = [...workout.moves]
            const move = _.find(moves, {'id': workoutMoveId});

            if (move) {
                comments = move.comments;
                moveId = move.move_id;
            }
        }

        const moveForm = {
            show: true,
            isNewMove: isNewMove,
            workoutMoveId,
            moveId: moveId,
            comments: comments
        }

        setMoveForm(moveForm);
    };

    const closeMoveForm = () => {
        setMoveForm({
            show: false,
            isNewMove: true,
            workoutMoveId: 0,
            moveId: 0,
            comments: '',
        });

        restoreScrollPosition();
    };

    const showEditedMove = (newMove) => {
        if (workout) {
            const moveIndex = _.findIndex(workout.moves, {'id': newMove.id});
            const w = _.cloneDeep(workout);

            if (moveIndex >= 0) {
                // Update existing move
                w.moves[moveIndex] = newMove;
            } else {
                // Add new
                w.moves.push(newMove);
            }

            setWorkout(w);
            closeMoveForm();
        }
    };

    const deleteMove = (moveId) => {
        WorkoutMoveHelper.deleteMove(
            workout.id, moveId
        ).then(function (response) {
            let w  =_.cloneDeep(workout);

            _.remove(w.moves, function (item) {
                return item.id === moveId;
            });

            if (response.linkGroupDeleted) {
                _.forEach(w.moves, function (item, key) {
                    if (item.set_group == response.linkGroup) {
                        item.set_group = 0;
                    }
                });
            }

            setWorkout(w);
        }.bind(this))
            .catch(error => {
                setError('Poisto epäonnistui.');
            })
    }

    const handleDragEnd = async (event) => {
        // If moving from back to front -> clicked item is before over item
        // If moving from front to back -> clicked item is after over item
        const clicked = event.active; // Clicked item
        const over = event.over; // Moving clicked item over this item
        const clickedIndex = clicked.data.current.sortable.index;
        const overIndex = over.data.current.sortable.index;

        let w = _.cloneDeep(workout);
        const moves = w.moves;

        moves.map((item, index) => {
            item.order = index + 1;
            item.clicked = 0;
        });

        const clickedListIndex = _.findIndex(moves, ['id', clicked.id]);
        moves[clickedListIndex].order = clickedIndex < overIndex ? overIndex + 1 : overIndex;
        moves[clickedListIndex].clicked = 1;

        const overListIndex = _.findIndex(moves, ['id', over.id]);
        moves[overListIndex].order = overIndex + 1;

        // Sort items based on new order. If two items have the same order, non-clicked is first.
        const sortedItems = _.sortBy(moves, ['order', 'clicked']);

        sortedItems.map((item, index) => {
            //item.order = index + 1;
            delete item.order;
            delete item.clicked;
        });

        w.moves = sortedItems;

        try {
            const clickedSortedIndex = _.findIndex(sortedItems, ['id', clicked.id]);

            await WorkoutMoveHelper.updateMoveOrder(
                workout.id,
                clicked.id,
                clickedSortedIndex + 1
            );
            setWorkout(w);
        } catch (e) {
        }
    };

    const selectRPE = async (value) => {
        try {
            const updated = await WorkoutHelper.updateRPE(workout.id, value);

            let w = _.cloneDeep(workout);
            w.rpe = value;
            setWorkout(w);
        } catch (e) {
        }
    };

    let moves = "";

    if (workout.moves) {
        let groups = 0; // supersets
        let lastGroupNro = 0;
        const nroOfMoves = workout.moves.length;
        const allMoves = workout.moves;

        moves = allMoves.map((move, index) => {
            let setGroupNro = 0;
            let nextMoveInDifferentGroup = false;
            let firstOrLast = false;

            if (move.set_group > 0) {
                if (move.set_group !== lastGroupNro) {
                    groups++;
                    lastGroupNro = move.set_group;
                }

                setGroupNro = groups;

                // Is move first in group or next move last in group
                const groupMoves = _.filter(allMoves, {'set_group': move.set_group});
                const firstMove = _.head(groupMoves);
                const lastMove = _.last(groupMoves);

                if (firstMove.id === move.id) {
                    firstOrLast = true;
                }

                // Check if this move and next move are in different groups
                if (allMoves[index + 1]) {
                    const nextMove = allMoves[index + 1];

                    if (lastMove.id === nextMove.id || lastMove.id === move.id) {
                        firstOrLast = true;
                    }

                    if (nextMove.set_group > 0 && nextMove.set_group !== move.set_group) {
                        // Next move has been added to a different group
                        nextMoveInDifferentGroup = true;
                    }
                }
            } else {
                firstOrLast = true;
            }

                return (
                    <WorkoutMove
                        key={move.id}
                        id={move.id}
                        order={index + 1}
                        name={move.name}
                        comments={move.comments}
                        setGroupNro={setGroupNro}
                        nroOfMoves={nroOfMoves}
                        programId={workout.program_id}
                        programComments={move.program_comments}
                        setGroups={move.set_groups}
                        lastMove={move.last_move}
                        previousMoves={move.previous_moves}
                        refValue={move.ref_value}
                        nextMoveInDifferentGroup={nextMoveInDifferentGroup}
                        firstOrLast={firstOrLast}
                        showMoveForm={showMoveForm}
                        showSetForm={showSetForm}
                        deleteSet={deleteSet}
                        deleteMove={deleteMove}
                        toggleLinkWithNextMove={toggleLinkWithNextMove}
                        setIsOpen={toggleMove}
                        isOpen={_.findIndex(collapsedMoves, function(c) {
                            return c === move.id;
                        }) === -1}
                    />
                )
            })
        }

        // Page title
        let pageTitle = "Salitreeni";

        if (workout.title) {
            pageTitle = workout.title;
        }

        return (
            <Row className="mb-5">

                {(setForm.show && !moveForm.show) && (
                    <Col xs={12}>
                        <SetForm
                            key={setForm.moveId}
                            workoutId={workout.id}
                            moveId={setForm.moveId}
                            setGroup={setForm.setGroup}
                            initialData={setForm.setData}
                            closeForm={closeSetForm}
                            showEditedSet={showEditedSet}
                        />
                    </Col>
                )}

                {(!setForm.show && moveForm.show) && (
                    <Col xs={12}>
                        <WorkoutMoveForm
                            workoutId={workout.id}
                            workoutMoveId={moveForm.workoutMoveId}
                            isNewMove={moveForm.isNewMove}
                            initialMoveId={moveForm.moveId}
                            initialComments={moveForm.comments}
                            closeForm={closeMoveForm}
                            showEditedMove={showEditedMove}
                        />
                    </Col>
                )}

                {(!setForm.show && !moveForm.show) && (
                    <Col xs={12}>
                        <Row>

                            <ContentHeader title={pageTitle}/>

                            {error ? (
                                <Col xs={12}>
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                </Col>
                            ) : (

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} className="text-center mt-n5">
                                            {workout.date && (
                                                <span>
                                                {DateTimeHelper.formatDateTime(workout.date)}
                                            </span>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="text-muted">
                                            {workout.program_description && (
                                                workout.program_description.split("\n").map((i, key) => {
                                                    return <div key={key}>{i}</div>;
                                                }))}
                                        </Col>
                                    </Row>

                                    <Row className="mt-5">
                                        <Col xs={12}>
                                            <DndContext onDragEnd={handleDragEnd}>
                                                <SortableContext items={workout.moves ?? [] }>
                                                    {moves}
                                                </SortableContext>
                                            </DndContext>
                                        </Col>

                                        <Col xs={12} className="text-right">
                                            <Button variant="light"
                                                    onClick={() => showMoveForm(true, 0)}>
                                                <FontAwesomeIcon icon={faPlus} /> Lisää liike
                                            </Button>

                                            <Link to={RouteHelper.getProgramStatsUrl(workout.program_id)}
                                                className="ml-2">
                                                <Button variant="light">
                                                    <FontAwesomeIcon icon={faChartLine} /> Näytä tilastot
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Row className="mt-5">
                                        <Col xs={12} className="text-center">
                                            <h6>Treenin kuormittavuus</h6>
                                        </Col>
                                        <Col xs={12}>
                                            <RPESelector
                                                selectedRPE={workout.rpe}
                                                selectRPE={selectRPE}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>
                )}
            </Row>
        )
}

export default GymWorkout;