import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Dashboard from './dashboard/Dashboard';
import Login from "./login/Login";
import WorkoutPrograms from "./programs/WorkoutPrograms";
import PrivateRoute from "./PrivateRoute";
import { isUserLogged } from "../redux/reducers/user";
import { connect } from "react-redux";
import NewProgram from "./programs/NewProgram";
import EditWorkoutProgram from "./programs/EditWorkoutProgram";
import NewProgramDay from "./programs/days/NewProgramDay";
import EditProgramDay from "./programs/days/EditProgramDay";
import ProgramDayStats from "./programs/days/ProgramDayStats";
import NewProgramMove from "./programs/moves/NewProgramMove";
import EditProgramMove from "./programs/moves/EditProgramMove";
import GymWorkouts from "./workouts/GymWorkouts";
import GymWorkout from "./workouts/GymWorkout";
import BodyStats from "./stats/BodyStats";

class Content extends React.Component {
    render() {
        return (
            <Container>
                <div>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                this.props.logged ? <Navigate to="/dashboard" /> : <Login />
                            }
                        />

                        <Route path="/inbody" element={<BodyStats />} />

                        {/* Private Routes */}
                        <Route
                            path="/dashboard"
                            element={<PrivateRoute element={<Dashboard />} logged={this.props.logged} />}
                        />

                        <Route
                            path="/workouts"
                            element={<PrivateRoute element={<GymWorkouts />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/workouts/:id"
                            element={<PrivateRoute element={<GymWorkout />} logged={this.props.logged} />}
                        />

                        <Route
                            path="/programs"
                            element={<PrivateRoute element={<WorkoutPrograms />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/programs/new"
                            element={<PrivateRoute element={<NewProgram />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/programs/:id/edit"
                            element={<PrivateRoute element={<EditWorkoutProgram />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/programs/day/new"
                            element={<PrivateRoute element={<NewProgramDay />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/programs/:programId/day/:dayId/edit"
                            element={<PrivateRoute element={<EditProgramDay />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/programs/move/new"
                            element={<PrivateRoute element={<NewProgramMove />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/programs/:programId/days/:dayId/move/:moveId/edit"
                            element={<PrivateRoute element={<EditProgramMove />} logged={this.props.logged} />}
                        />
                        <Route
                            path="/programs/:programId/stats"
                            element={<PrivateRoute element={<ProgramDayStats />} logged={this.props.logged} />}
                        />
                    </Routes>
                </div>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        logged: isUserLogged(state),
    };
}

export default connect(mapStateToProps)(Content);
