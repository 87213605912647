import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Button } from "react-bootstrap";
import WorkoutProgramDayForm from "./WorkoutProgramDayForm";
import ProgramDayHelper from "../../../helpers/programs/ProgramDayHelper";
import ContentHeader from "../../ContentHeader";

function NewProgramDay() {
    const location = useLocation();
    const navigate = useNavigate();

    const [programId, setProgramId] = useState('');
    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (location.state && Number.isInteger(location.state.programId)) {
            setProgramId(location.state.programId);
            setShowForm(true);
        } else {
            setError('Harjoitusohjelmaa ei ole valittuna.');
            setShowForm(false);
        }
    }, [location.state]);

    const createDay = (name) => {
        ProgramDayHelper.createDay(programId, name)
            .then(() => {
                navigate('/programs'); // Redirect to programs
            })
            .catch(() => {
                setError('Harjoituspäivän luonti epäonnistui.');
            });
    };

    const showError = (text) => {
        setError(text);
    };

    return (
        <Row className="mb-5">
            <ContentHeader title="Uusi harjoituspäivä" />

            {error && (
                <Col xs={12} className="mb-3">
                    <Alert variant="danger">{error}</Alert>
                </Col>
            )}

            {showForm ? (
                <Col xs={12}>
                    <WorkoutProgramDayForm
                        saveBtnText="Tallenna"
                        showError={showError}
                        dayId=""
                        programId={programId}
                        loadData={false}
                        saveDay={createDay}
                        historyBack={() => navigate(-1)}
                    />
                </Col>
            ) : (
                <Button variant="default" onClick={() => navigate(-1)}>
                    Peruuta
                </Button>
            )}
        </Row>
    );
}

export default NewProgramDay;
