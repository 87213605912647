import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from "react-bootstrap/Card";
import WorkoutProgramMove from "../moves/WorkoutProgramMove";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import '../../../assets/css/program.css';
import { Button } from "react-bootstrap";
import WorkoutHelper, { WorkoutTypes } from "../../../helpers/workoutHelper";

function WorkoutProgramDay({ name, id, programId, dayId, moves }) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const startWorkout = () => {
        WorkoutHelper.createWorkout(WorkoutTypes.gym, id)
            .then((workout) => {
                navigate(`/workouts/${workout.id}`);
            })
            .catch((error) => {
                console.error("Error starting workout:", error);
            });
    };

    let latestSetGroup = 0;
    let supersets = 0;

    const moveElements = moves.map((move, index) => {
        let supersetGroupNro = 0;

        if (move.set_group > 0) {
            if (move.set_group !== latestSetGroup) {
                supersets++;
                latestSetGroup = move.set_group;
            }

            supersetGroupNro = supersets;
        }

        return (
            <WorkoutProgramMove
                key={move.id}
                programId={programId}
                dayId={dayId}
                move={move}
                order={index + 1}
                sets={move.sets}
                supersetGroupNro={supersetGroupNro}
            />
        );
    });

    return (
        <Row>
            <Col xs={12} className="mt-3">
                <Card>
                    <Card.Header>
                        <Row>
                            <Col
                                xs={12}
                                md={9}
                                onClick={toggleOpen}
                                className="clickable float-left"
                            >
                                {name}
                            </Col>
                            <Col xs={12} md={3}>
                                <Button
                                    variant="light"
                                    className="icon-btn float-right"
                                    onClick={startWorkout}
                                >
                                    <FontAwesomeIcon icon={faPlay} />
                                </Button>
                                <Button variant="light" className="icon-btn float-right mr-2">
                                    <Link to={`/programs/${programId}/day/${id}/edit`}>
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </Link>
                                </Button>
                                <Button variant="light" className="icon-btn float-right mr-2">
                                    <Link
                                        to="/programs/move/new"
                                        state={{ dayId: id, programId: programId, }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Link>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>

                    {isOpen && <div className="p-3">{moveElements}</div>}
                </Card>
            </Col>
        </Row>
    );
}

export default WorkoutProgramDay;