import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Button } from "react-bootstrap";
import WorkoutHelper, { WorkoutTypes } from "../../helpers/workoutHelper";
import GymWorkoutTile from "./GymWorkoutTile";
import _ from "lodash";
import ContentHeader from "../ContentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function GymWorkouts() {
    const [error, setError] = useState('');
    const [workouts, setWorkouts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        WorkoutHelper.getWorkouts(WorkoutTypes.gym)
            .then((workouts) => {
                setWorkouts(workouts);
            })
            .catch(() => {
                setError('Treenien haku epäonnistui.');
            });
    }, []);

    const deleteWorkout = (id) => {
        WorkoutHelper.deleteWorkout(id)
            .then(() => {
                setWorkouts((prevWorkouts) =>
                    prevWorkouts.filter((item) => item.id !== id)
                );
            })
            .catch((error) => {
                console.error("Error deleting workout:", error);
            });
    };

    const startWorkout = () => {
        WorkoutHelper.createWorkout(WorkoutTypes.gym, null)
            .then((workout) => {
                navigate(`/workouts/${workout.id}`);
            })
            .catch((error) => {
                console.error("Error starting workout:", error);
            });
    };

    const workoutTiles = workouts.map((item) => (
        <GymWorkoutTile
            key={item.id}
            id={item.id}
            title={item.title}
            date={item.date}
            rpe={item.rpe}
            deleteWorkout={deleteWorkout}
            programName={item.program_name}
        />
    ));

    return (
        <Row className="mb-5">
            <ContentHeader title="Salitreenit" />

            {error ? (
                <Col xs={12}>
                    <Alert variant="danger">{error}</Alert>
                </Col>
            ) : (
                <Col xs={12}>
                    <Row>
                        <Col xs={12} className="mb-3">
                            <Button
                                variant="light"
                                className="float-right"
                                onClick={startWorkout}
                            >
                                <FontAwesomeIcon icon={faPlay} /> Aloita treeni
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>{workoutTiles}</Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
}

export default GymWorkouts;
