import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert } from "react-bootstrap";
import ProgramMoveForm from "./ProgramMoveForm";
import ProgramMoveHelper from "../../../helpers/programs/ProgramMoveHelper";
import ContentHeader from "../../ContentHeader";

function EditProgramMove() {
    const { dayId, moveId, programId } = useParams();
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        dayId: '',
        moveId: '',
        programId: ''
    });

    useEffect(() => {
        const parsedDayId = parseInt(dayId);
        const parsedMoveId = parseInt(moveId);
        const parsedProgramId = parseInt(programId);

        if (!Number.isInteger(parsedDayId) || !Number.isInteger(parsedMoveId) || !Number.isInteger(parsedProgramId)) {
            showError("Harjoitusliikettä ei löytynyt.", false);
        } else {
            setFormData({
                dayId: parsedDayId,
                moveId: parsedMoveId,
                programId: parsedProgramId
            });
        }
    }, [dayId, moveId, programId]);

    const saveMove = (move, rest, comments, sets) => {
        ProgramMoveHelper.updateMove(
            formData.programId,
            formData.dayId,
            formData.moveId,
            move,
            rest,
            comments,
            sets
        )
            .then(() => {
                navigate('/programs'); // Redirect to /programs
            })
            .catch(() => {
                showError("Päivitys epäonnistui", true);
            });
    };

    const showError = (text, showFormFlag) => {
        setError(text);
        setShowForm(showFormFlag);
    };

    return (
        <Row className="mb-5">
            <ContentHeader title="Muokkaa harjoitusliikettä" />

            {error && (
                <Col xs={12} className="mb-3">
                    <Alert variant="danger">{error}</Alert>
                </Col>
            )}

            <Col xs={12}>
                <ProgramMoveForm
                    programId={formData.programId}
                    dayId={formData.dayId}
                    moveId={formData.moveId}
                    showError={showError}
                    loadData={true}
                    saveMove={saveMove}
                    historyBack={() => navigate(-1)}
                />
            </Col>
        </Row>
    );
}

export default EditProgramMove;
