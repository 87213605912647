import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Button } from "react-bootstrap";
import ProgramMoveForm from "./ProgramMoveForm";
import ProgramMoveHelper from "../../../helpers/programs/ProgramMoveHelper";
import ContentHeader from "../../ContentHeader";

function NewProgramMove() {
    const location = useLocation();
    const navigate = useNavigate();

    const [dayId, setDayId] = useState('');
    const [programId, setProgramId] = useState('');
    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (location.state && Number.isInteger(location.state.dayId) && Number.isInteger(location.state.programId)) {
            setDayId(location.state.dayId);
            setProgramId(location.state.programId);
            setShowForm(true);
        } else {
            setError('Harjoituspäivää ei ole valittuna.');
            setShowForm(false);
        }
    }, [location.state]);

    const createMove = (move, rest, comments, sets) => {
        ProgramMoveHelper.createMove(programId, dayId, move, rest, comments, sets)
            .then(() => {
                navigate('/programs'); // Redirect to programs
            })
            .catch(() => {
                setError('Harjoitusliikkeen luonti epäonnistui.');
            });
    };

    const showError = (text, showFormFlag) => {
        setError(text);
        setShowForm(showFormFlag);
    };

    return (
        <Row className="mb-5">
            <ContentHeader title="Uusi harjoitusliike" />

            {error && (
                <Col xs={12} className="mb-3">
                    <Alert variant="danger">{error}</Alert>
                </Col>
            )}

            {showForm ? (
                <Col xs={12}>
                    <ProgramMoveForm
                        showError={showError}
                        loadData={false}
                        saveMove={createMove}
                        historyBack={() => navigate(-1)}
                    />
                </Col>
            ) : (
                <Button variant="default" onClick={() => navigate(-1)}>
                    Peruuta
                </Button>
            )}
        </Row>
    );
}

export default NewProgramMove;
