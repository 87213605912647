import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert } from "react-bootstrap";
import ProgramHelper from "../../helpers/programs/ProgramHelper";
import WorkoutProgramForm from "./WorkoutProgramForm";
import ContentHeader from "../ContentHeader";

function EditWorkoutProgram() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [error, setError] = useState('');

    const saveProgram = (name, description) => {
        ProgramHelper.updateProgram(id, name, description)
            .then(() => {
                // Redirect to programs
                navigate('/programs');
            })
            .catch(() => {
                setError('Päivitys epäonnistui.');
            });
    };

    const showError = (text) => {
        setError(text);
    };

    return (
        <Row className="mb-5">
            <ContentHeader title="Muokkaa ohjelmaa" />

            {error && (
                <Col xs={12}>
                    <Alert variant="danger">{error}</Alert>
                </Col>
            )}

            <Col xs={12}>
                <WorkoutProgramForm
                    saveBtnText="Tallenna"
                    showError={showError}
                    id={id}
                    loadData={true}
                    saveProgram={saveProgram}
                    historyBack={() => navigate(-1)}
                />
            </Col>
        </Row>
    );
}

export default EditWorkoutProgram;
